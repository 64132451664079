import events$ from '@/services/Events'

import { logInfo, logWarning } from '@/helpers/Logger'

import { EVENT_CHAT, EVENT_LOGIN, EVENT_URL } from '@/config/events'
import { CHANNEL } from '@/config/constants'

export default function useUrl() {
  // METHODS
  function sanitizePrefillParams(data) {
    const whitelistKeys = [
      'firstName',
      'gender',
      'dateOfBirth',
      'primeRegion',
      'locationNumber',
      'zipCode',
      'zipCodeAddon',
    ]

    const decodedParams = {}
    const encodedData = data

    atob(encodedData)
      .split(';')
      .filter(i => i.length > 0)
      .forEach(item => {
        const __data = item.split('=')
        if (!whitelistKeys.includes(__data[0])) {
          throw Error('invalid param')
        }

        decodedParams[__data[0]] = decodeURIComponent(__data[1].trim())
      })

    if (whitelistKeys.length !== Object.keys(decodedParams).length) {
      throw Error('invalid param')
    }

    return decodedParams
  }

  function _getChannelByPlatform(platform) {
    switch (platform) {
      case 'ios':
      case 'android':
        return CHANNEL.PORTAL_APP

      case 'web':
        return CHANNEL.PORTAL_WEB
      default:
        return CHANNEL.OVP
    }
  }

  function _openLoginModal() {
    const payload = {
      step: getParamFromUrl('step'),
      platform: getParamFromUrl('platform'),
    }

    // Set correct channel depending on the platform (FLEX-3359)
    payload.channel = _getChannelByPlatform(payload.platform)

    // @note: as we currently don't know, which login-modal we open,
    // we open all the login-modals on a page via the open-all prop
    payload.openAll = true

    // @note: as the a11dialog is instantiated after a next-tick, we have to wait for some time
    // before triggering to open the modal
    const t0 = performance.now()
    const msToRun = 10000
    const modalInterval = setInterval(() => {
      const isModalInDOM = document.querySelector('[id*="login-modal"]')
      if (isModalInDOM) {
        clearInterval(modalInterval)
        events$.emit(EVENT_LOGIN.OPEN, payload)
        return
      }

      if (performance.now() - t0 > msToRun) {
        clearInterval(modalInterval)
        //window.ineum('reportError', 'No login modal found')
      }
    }, 100)
  }

  function getParamFromUrl(key) {
    return (
      new URLSearchParams(window.location.search).get(key) ||
      new URLSearchParams(window.location.hash?.split('?')?.[1]).get(key)
    )
  }

  function react() {
    const open = getParamFromUrl('open')
    const goto = getParamFromUrl('goto')

    if (open) {
      const components = open.split(',')
      components.forEach(component => {
        switch (component) {
          case 'chat':
            logInfo('OPEN CHAT')
            events$.on(EVENT_CHAT.LOADED, () => {
              events$.emit(EVENT_CHAT.OPEN)
            })
            break

          case 'login': {
            _openLoginModal()
            break
          }

          case 'prefill':
            // @note: is used in SetupDVPWidget and ScreenPersons
            break

          default:
            logWarning(`useUrl can not open unknown option: ${component}`)
        }
      })
    } else if (goto) {
      events$.emit(EVENT_URL.GOTO, {
        target: goto,
      })
    }
  }

  return {
    getParamFromUrl,
    react,
    sanitizePrefillParams,
  }
}
