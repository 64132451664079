<template>
  <!-- prettier-ignore -->
  <div
    class="switch-button"
    :class="classes"
  >
    <div
      v-for="item in $props.items"
      :key="item.value"
    >
      <basic-radio
        v-model="model"
        :disabled="$props.disabled && item.value !== model"
        :label="item.text"
        :is-invalid="isInvalid"
        :name="$props.name"
        :value="item.value"
        @update:modelValue="emitUpdate(item.value)"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { logWarning } from '@/helpers/Logger'

import BasicRadio from '@/components/Basic/Radio'

// INIT
const model = defineModel({ type: [String, Number, Boolean] })
const props = defineProps({
  direction: {
    type: String,
    default: 'row',
    validator(val) {
      return ['column', 'row'].includes(val)
    },
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  items: {
    type: Array,
    required: true,
    validator(val) {
      const isValidObj = val.every(obj => Object.hasOwn(obj, 'value') && Object.hasOwn(obj, 'text'))
      if (!isValidObj) {
        logWarning('[Vue Warn]: Invalid prop: items should have the following properties: {value, text}')
        return false
      }
      return true
    },
  },

  manualValidation: {
    type: Boolean,
    default: false,
  },

  name: {
    type: String,
    required: true,
  },

  size: {
    validator(value) {
      return ['sm', 'md', 'lg'].includes(value)
    },
    default: 'lg',
  },

  v: {
    type: Object,
    default: () => {},
  },
})

// COMPUTED
const classes = computed(() => {
  return {
    [`switch-button--${props.direction}`]: true,
    [`switch-button--${props.size}`]: true,
    'switch-button--invalid': isInvalid.value,
  }
})

const isInvalid = computed(() => {
  return props.v?.$invalid && props.v?.$error
})

// METHODS
function emitUpdate(value) {
  model.value = value
}
</script>

<style scoped>
.switch-button {
  --switch-btn-color-text: var(--Interaction-States-Chips-Positives-on-unselected-enabled-positive);
  --switch-btn-color-bg: var(--Interaction-States-Chips-Positives-unselected-enabled-positive);
  --switch-btn-color-border: var(--outline-variant);
  --switch-btn-padding: var(--dotcom-responsive-spacing-res-xxs) var(--dotcom-responsive-spacing-res-xxs);

  display: flex;
  flex-direction: column;
  margin: 1px 0 0 1px; /* Prevent double border sorcery */

  &--row {
    flex-direction: row;
  }

  &:deep(:first-child .radio) {
    border-top-left-radius: var(--fixed-border-radius-fix-07);
    border-bottom-left-radius: var(--fixed-border-radius-fix-07);
  }

  &:deep(:last-child .radio) {
    border-top-right-radius: var(--fixed-border-radius-fix-07);
    border-bottom-right-radius: var(--fixed-border-radius-fix-07);
  }

  &:deep(.radio) {
    padding: var(--switch-btn-padding);
    background-color: var(--switch-btn-color-bg);
    color: var(--switch-btn-color-text);
    border: 1px solid var(--switch-btn-color-border);
    margin: -1px 0 0 -1px; /* Prevent double border sorcery */
    text-align: center;
    cursor: pointer;
    line-height: var(--line-height-bold-sm);
    height: var(--dotcom-responsive-spacing-res-l);
    display: flex;
    align-items: center;
  }

  &:deep(.radio--selected) {
    --switch-btn-color-text: var(--Interaction-States-Chips-Positives-on-selected-enabled-positive);
    --switch-btn-color-bg: var(--Interaction-States-Chips-Positives-selected-enabled-positive);
    --switch-btn-color-border: var(--secondary);
    z-index: var(--dvp-stack-level-element);
  }

  &:deep(.radio--selected .radio__label) {
    color: var(--Interaction-States-Chips-Positives-on-selected-enabled-positive);
  }

  &:deep(.radio__icon),
  &:deep(.radio__input) {
    display: none;
  }

  &:deep(.radio__label) {
    padding: 0 var(--dotcom-responsive-spacing-res-s) 0 var(--dotcom-responsive-spacing-res-s);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-bold-sm);
    letter-spacing: var(--letter-spacing-bold-sm);
    line-height: var(--line-height-bold-sm);
  }
}
</style>
