import { MissingParameterError } from '@/utils/CustomErrors'

import config from '@/config'

import useBackend from '@/hooks/useBackend'

function incentive() {
  // HOOKS
  const { doGet, setup } = useBackend()

  // INIT
  setup('/ovpv3/public', config.microserviceBaseUrl)

  // METHODS

  /**
   *  fetchIncentive sends a GET-request to get the active incentive
   *  @param {object}   payload
   *  @param {string}   payload.language
   *  @param {string}   payload.channel
   *  @param {string}   payload.distributionPartnerNumber
   *  @param {boolean}  payload.immigrant
   *  @returns Promise<Incentive> incentive
   */
  async function fetchIncentives(payload) {
    if (!payload.language) throw new MissingParameterError('language')
    if (!payload.channel) throw new MissingParameterError('channel')
    if (!payload.distributionPartnerNumber) throw new MissingParameterError('distributionPartnerNumber')
    if (payload.immigrant === undefined) throw new MissingParameterError('immigrant')
    if (payload.existingCustomer === undefined) throw new MissingParameterError('existingCustomer')

    const response = await doGet({ url: '/incentives', params: payload })
    return response.data
  }

  return {
    fetchIncentives,
  }
}

export default incentive()
