<template>
  <!-- prettier-ignore -->
  <div
    v-if="isActive"
    class="pricing"
    :class="{ [`pricing--${props.size}`]: true }"
  >
    <div v-if="collectiveContractReduction">
      <div
        class="body-3 pricing__collective"
        v-text="collectiveContractReduction"
      />
    </div>

    <div class="pricing__bubbles">
      <template
        v-for="(item, index) in items"
        :key="index"
      >
        <div
          v-tooltip="item.tooltip"
          class="body-2"
          :class="item.class"
          tabindex="0"
        >
          <template v-if="$props.size === 'md'">
            <span
              class="body-3"
              v-text="$t(item.label)"
            />
          </template>

          <the-icon
            art="light"
            :name="item.icon"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import useI18n from '@/hooks/useI18n'

const KEYS = {
  COLLECTIVE: 'RD_RAHMENVERTRAGSRABATT',
  IPZ: 'CT_RISIKOZUSCHLAG',
}

// HOOKS
const { t } = useI18n()

// INIT
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },

  size: {
    type: String,
    validator(value) {
      return ['sm', 'md'].includes(value)
    },
    default: 'md',
  },
})

// COMPUTED
const collectiveContractReduction = computed(() => {
  const item = selectedOption.value.reductions?.filter(r => r.type === KEYS.COLLECTIVE)?.[0]

  if (!item) return false
  const fullPrice = Math.abs(props.product.prices.find(p => p.selected).price + -1 * item.amount).toFixed(2)

  return `${t('product.insteadOf')} ${fullPrice}, ${getReductionLabel(item)}`
})

const isActive = computed(() => {
  return selectedOption.value.reductions.length || props.product.conditionalProduct
})

const items = computed(() => {
  const data = []

  // IPZ
  const ipz = selectedOption.value.reductions?.filter(r => r.type === KEYS.IPZ) ?? []
  if (ipz.length) {
    data.push({
      class: 'pricing__ipz',
      icon: 'circle-info',
      label: 'product.ipz',
      tooltip: ipz.map(r => getReductionLabel(r)).join('; '),
    })
  }

  // Penalties
  const penalties = selectedOption.value.reductions?.filter(r => r.type !== KEYS.IPZ && r.amount > 0) ?? []
  if (penalties.length) {
    data.push({
      class: 'pricing__caveat',
      icon: 'exclamation-circle',
      label: 'product.penalty',
      tooltip: penalties.map(r => getReductionLabel(r)).join('; '),
    })
  }

  // Reductions
  const reductions =
    selectedOption.value.reductions?.filter(r => r.type !== KEYS.IPZ && r.type !== KEYS.COLLECTIVE && r.amount < 0) ??
    []
  if (reductions.length) {
    data.push({
      class: 'pricing__reduction',
      icon: 'badge-percent',
      label: 'product.reduction',
      tooltip: reductions.map(r => getReductionLabel(r)).join('; '),
    })
  }

  // Caveat
  if (props.product.conditionalProduct) {
    data.push({
      class: 'pricing__caveat',
      icon: 'exclamation-circle',
      label: 'product.caveat.title',
      tooltip: t('product.caveat.text'),
    })
  }

  return data
})

const selectedOption = computed(() => {
  return props.product.prices.find(p => p.selected) || {}
})

// METHODS
function getReductionLabel(__reduction) {
  const type = t(`info.${__reduction.type}`)
  const amount = Math.abs(__reduction.amount).toFixed(2)

  return `${type} ${amount} ${t('product.currency')}`
}
</script>

<style scoped>
.pricing {
  display: flex;
  flex-direction: column;
  gap: var(--fixed-spacing-fix-02);

  .pricing__collective {
    background-color: var(--surface-ext-b-medium);
    padding: var(--fixed-spacing-fix-01) var(--spacing-san-spacing-02);
    border-radius: var(--radius-border-radius-02);
    color: var(--on-surface);
  }

  .pricing__bubbles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 4px;
    line-height: initial;

    --Pricing-reduction-background: var(--colors-tertiary-900);

    --Pricing-penalty-background: var(--colors-attention-900);

    --Pricing-ipz-background: var(--colors-warning-700);

    --Pricing-caveat-background: var(--colors-attention-900);

    &:empty {
      display: none;
    }

    .pricing__reduction,
    .pricing__penalty,
    .pricing__caveat,
    .pricing__ipz {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 4px;
      align-items: center;
      border-radius: var(--radius-border-radius-05);
      height: 20px;
      padding: 0 var(--spacing-san-spacing-02);
      text-wrap: nowrap;
      line-height: initial;

      &:focus-visible {
        outline: 2px solid var(--lnk-c-outline);
        outline-offset: var(--fixed-spacing-fix-01);
        border-radius: var(--fixed-border-radius-fix-02);
      }
    }

    .pricing__reduction {
      background: var(--Pricing-reduction-background);
    }

    .pricing__ipz {
      background: var(--Pricing-ipz-background);
    }

    .pricing__penalty {
      background: var(--Pricing-penalty-background);
    }

    .pricing__caveat {
      background: var(--Pricing-caveat-background);
    }
  }
}

.pricing--sm {
  .pricing__reduction,
  .pricing__penalty,
  .pricing__caveat,
  .pricing__ipz {
    padding: 0 var(--spacing-san-spacing-01);
  }
}
</style>
