<template>
  <basic-modal
    id="authModal"
    ref="authModalRef"
    type="transactional"
    :closeable="false"
    :primary-label="primaryActionLabel"
    :secondary-label="secondaryActionLabel"
    @click-primary="primaryActionClicked"
    @click-secondary="endSession"
  >
    <template #title>
      <div
        class="auth__title"
        :class="{ 'auth__title--centered': currentState === STATE.LOGIN }"
        v-text="$t('screen.auth.title')"
      />
    </template>
    <template #default>
      <div class="auth">
        <template v-if="currentState === STATE.LOGIN">
          <basic-form
            ref="tanFormRef"
            class="auth-form"
            autocomplete="off"
            name="auth"
            tracking-disabled
            :validator="v"
            @submit="submit"
          >
            <tan
              v-model="formData.tan"
              :mobile="phone"
              :show-policy="false"
              :token="formData.token"
              :v="v.tan"
              @keydown.enter="submit"
            />
          </basic-form>
        </template>

        <template v-else-if="currentState === STATE.TIMED_OUT">
          <div v-text="$t('screen.auth.forcedLogout.description')" />
        </template>

        <template v-else-if="currentState === STATE.WARNING">
          <div v-text="$t('screen.auth.request.description')" />
          <div v-text="$t('screen.auth.recipient', { phone })" />
        </template>
      </div>
    </template>
  </basic-modal>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import { loading$, events$ } from '@/services'

import { getNextRoute } from '@/helpers/Route'

import basketAPI from '@/api/basket'
import basketStore from '@/store/basket'
import sessionStore from '@/store/session'
import useApplication from '@/hooks/useApplication'
import useI18n from '@/hooks/useI18n'
import useStatus from '@/hooks/useStatus'
import useSnackbar from '@/hooks/useSnackbar'

import { BasicForm, BasicModal } from '@/components/Basic'
import Tan from '@/components/Authentication/Tan.vue'

import { EVENT_BASKET, EVENT_OVP, EVENT_SESSION } from '@/config/events'
import { FORCED_LOGOUT_KEY, SESSION_UPDATED_KEY, STORAGE_KEY } from '@/config/constants'

// HOOKS
const { isWww } = useApplication()
const { t } = useI18n()
const router = !isWww.value ? useRouter() : false
const { isOnboardingSecured } = useStatus()
const { addNotification } = useSnackbar()

// DATA
const checkerInterval = ref(undefined)
const formData = reactive({ tan: undefined, basketId: undefined, token: undefined })
const modalActive = ref(false)
const smsDisabled = ref(false)
const authModalRef = ref()
const STATE = {
  WARNING: 'warning', // session about to time out
  TIMED_OUT: 'timed-out', // dvp reloaded as session timed out
  LOGIN: 'login', // login again to extend/restart session
}
const currentState = ref(STATE.WARNING)
const tanFormRef = ref(null)

// COMPUTED
const primaryActionLabel = computed(() => {
  switch (currentState.value) {
    case STATE.LOGIN:
      return t('form.next')
    case STATE.TIMED_OUT:
      return t('screen.auth.forcedLogout.action')
    case STATE.WARNING:
    default:
      return t('screen.auth.request.requestTAN')
  }
})

const secondaryActionLabel = computed(() => {
  switch (currentState.value) {
    case STATE.TIMED_OUT:
      return t('screen.auth.forcedLogout.end')
    case STATE.LOGIN:
    case STATE.WARNING:
    default:
      return t('screen.auth.end')
  }
})

const phone = computed(() => {
  if (!basketStore.basket.mobile) return

  // TODO get mobile
  const start = basketStore.basket.mobile.substring(0, 5)
  const inbetween = basketStore.basket.mobile.substring(5, basketStore.basket.mobile.length - 2).replace(/\d/g, '*')
  const end = basketStore.basket.mobile.substring(basketStore.basket.mobile.length - 2)

  return `${start}${inbetween}${end}`
})

// METHODS
function checkForcedLogout() {
  const token = window.localStorage.getItem(FORCED_LOGOUT_KEY)
  if (token) {
    window.localStorage.removeItem(FORCED_LOGOUT_KEY)
    currentState.value = STATE.TIMED_OUT
    formData.token = token
    authModalRef.value.open()
  } else {
    // authModalRef.value.close()
  }
}

async function endSession() {
  try {
    await sessionStore.clearSession()
  } catch {
    // No session yet, just proceed
  }

  window.location.reload()
}

async function primaryActionClicked() {
  switch (currentState.value) {
    case STATE.LOGIN:
      tanFormRef.value.submit()
      break
    case STATE.TIMED_OUT:
    case STATE.WARNING:
    default:
      await sendTAN()
      break
  }
}

function off() {
  events$.off(EVENT_SESSION.UPDATE, updateSession)
  stopTimer()
}

function reset() {
  if (basketStore.basket.submitted) {
    off()
  } else if (isOnboardingSecured.value) {
    // if onboarding-secured and basket not yet submitted, register timer
    updateCheckerInterval()
  } else {
    checkForcedLogout()
  }
}

function resetCheckerInterval() {
  clearInterval(checkerInterval.value)
  checkerInterval.value = null
}

async function sendTAN() {
  loading$.start()
  smsDisabled.value = true

  stopTimer()

  try {
    const { basketId } = await basketAPI.sendSMSTan(formData.token)
    formData.basketId = basketId
    loading$.end()
    currentState.value = STATE.LOGIN
    addNotification({
      text: t('screen.auth.sent'),
    })
    // 2 minutes wait time: prevent the user from to many retries
    window.setTimeout(() => (smsDisabled.value = false), 120000)
  } catch {
    loading$.failed()
    smsDisabled.value = false
  }
}

/**
 * show auth-form modal
 *
 * turn off listener
 * start timer for session-ender
 *
 */
function showAuthForm() {
  // Prevent the session to timeout while loader is showing. Reset timer in this case.
  if (loading$.getState().active.value) {
    return
  }

  // Prevent user from only reloading to gain session again
  window.localStorage.removeItem(STORAGE_KEY)
  formData.token = basketStore.basket.reentryToken
  authModalRef.value.open()
}

function stopTimer() {
  resetCheckerInterval()
}

/**
 * submit tan
 *
 * when response is submitted => redirect to nextSteps
 * when response is !submitted => turn on listener
 */
async function submit() {
  // clear timeouts
  stopTimer()

  try {
    loading$.start({ blocking: false })
    const __basket = await basketStore.getBasket(formData)

    formData.tan = null
    formData.token = null
    formData.basketId = null
    currentState.value = STATE.WARNING
    v.value.$reset()
    reset()
    loading$.end()
    authModalRef.value.close()

    const nextRoute = getNextRoute(__basket)

    // @todo: what should happen in dotcom-env?
    if (router) {
      await router?.push({ name: nextRoute })
    }
  } catch {
    loading$.failed()
    formData.tan = null
    reset()
  }
}

function updateCheckerInterval() {
  checkerInterval.value =
    checkerInterval.value ||
    setInterval(() => {
      const sessionUpdated = parseInt(window.sessionStorage.getItem(SESSION_UPDATED_KEY), 10)
      if (!sessionUpdated) {
        resetCheckerInterval()
        return
      }
      const now = new Date().getTime()
      const VITE_SESSION_CLEAR = parseInt(import.meta.env.VITE_SESSION_CLEAR)
      const VITE_SESSION_AUTH = parseInt(import.meta.env.VITE_SESSION_AUTH)

      if (now - sessionUpdated >= VITE_SESSION_CLEAR) {
        window.localStorage.setItem(FORCED_LOGOUT_KEY, basketStore.basket.reentryToken)
        endSession()
      } else if (now - sessionUpdated >= VITE_SESSION_AUTH && !modalActive.value) {
        showAuthForm()
      }
    }, 1000)
}

function updateSession() {
  window.sessionStorage.setItem(SESSION_UPDATED_KEY, new Date().getTime().toString())
}

// VALIDATION
const v = useVuelidate(
  {
    tan: {
      required,
    },
  },
  formData,
  { $lazy: true }
)

// LIFECYCLE HOOKS
onMounted(() => {
  events$.on(EVENT_OVP.APPLICATION_SUBMITTED, () => {
    off()
  })
  events$.on(EVENT_BASKET.UPDATED, reset)
  events$.on(EVENT_SESSION.UPDATE, updateSession)
  reset()
})

onBeforeUnmount(() => {
  off()
  events$.off(EVENT_BASKET.UPDATED, reset)
})
</script>

<style name="mobile" scoped>
.auth {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  color: var(--c-primary-neutral-1);
  padding-bottom: 20px;

  button {
    min-width: 200px;
  }
}

.auth__title--centered {
  text-align: center;
}

.auth__input {
  margin-bottom: 20px;
}

.auth__button {
  color: var(--c-primary-color-3);
  display: block;
}

.auth__end {
  display: block;
}
</style>
