import { ref, readonly } from 'vue'

import { logInfo } from '@/helpers/Logger'
import { getDistributionPartnerNumber } from '@/helpers/Partner'

import incentiveAPI from '@/api/incentive'
import useBasket from '@/hooks/useBasket'

import { CHANNEL } from '@/config/constants'
import { events$ } from '@/services'
import { EVENT_INCENTIVES } from '@/config/events'

// GLOBAL DATA
const incentives = ref([])

export default function useIncentive() {
  // HOOKS
  const { hasImmigratedPerson } = useBasket()

  // METHODS

  function addIdsToIncentives(incentiveData) {
    if (!incentiveData) return []

    return incentiveData.map(incentive => {
      return {
        ...incentive,
        id: generateIdFromName(incentive.name),
      }
    })
  }

  async function installIncentiveOnUpdate(basket, originalBasket) {
    if (!basket.basketId) return

    const needsUpdateOrInstall =
      (originalBasket === undefined && basket !== undefined) ||
      (basket.language && basket.language !== originalBasket.language) ||
      basket.channel !== originalBasket.channel ||
      hasImmigratedPerson(basket) !== hasImmigratedPerson(originalBasket) ||
      getDistributionPartnerNumber(basket.distributionPartners) !==
        getDistributionPartnerNumber(originalBasket.distributionPartners)

    if (needsUpdateOrInstall) {
      await install(basket)
    }
  }

  async function install(basket) {
    const { language, channel } = basket
    const distributionPartnerNumber = getDistributionPartnerNumber(basket.distributionPartners)
    const data = await incentiveAPI.fetchIncentives({
      language,
      channel: channel || CHANNEL.OVP,
      immigrant: hasImmigratedPerson(basket),
      distributionPartnerNumber,
      existingCustomer: !!basket.existingCustomer,
    })

    return setIncentive(data)
  }

  function generateIdFromName(name) {
    return name.replace(/\s+/g, '').toLowerCase()
  }

  function setIncentive(payload) {
    incentives.value = addIdsToIncentives(payload)

    const incentivesIds = incentives.value.map(i => i.id).join(';')

    events$.emit(EVENT_INCENTIVES.LOADED, incentivesIds)
    logInfo(['%cINCENTIVES SET', 'color: green', incentives.value])
    return incentives
  }

  return {
    incentives: readonly(incentives),
    install,
    installIncentiveOnUpdate,
  }
}
